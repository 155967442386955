/* Global styles */
body {
  background-color: #FFD6FF;
  color: #333; /* Default text color */
}

/* Navbar styles */
.navbar {
  background-color: #E7C6FF;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: #333; /* Navbar text color */
}

.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: #fff; /* Navbar text color on hover */
}

/* Footer styles */
.footer {
  background-color: #BBD0FF;
  color: #333; /* Footer text color */
}

/* Button styles */
.btn-primary {
  background-color: #C8B6FF;
  border-color: #C8B6FF;
}

.btn-primary:hover {
  background-color: #B8C0FF;
  border-color: #B8C0FF;
}

/* Apply specific colors to elements as needed */
